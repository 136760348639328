import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const AshGuidesIntroPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page ash'} game="ash">
      <ul className="breadcrumb">
        <li>
          <Link to="/ash-echoes/">Ash Echoes</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/ash-echoes/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Introduction to the game</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/ash/categories/category_intro.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Introduction to the game</h1>
          <h2>Basic information about the Ash Echoes gacha game.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="About the game" />
        <p>
          Ash Echoes is an interdimensional real-time tactical RPG blending
          strategy, exploration, and narrative. Built on Unreal Engine, the game
          delivers a stunning combination of 2D and 3D art, creating a rich and
          immersive world. The game was released in China earlier this year, but
          the Global version of the game, that will be published by Neocraft,
          will be released in Q4 2024.
        </p>
        <h5>Story</h5>
        <p>
          <i>
            In Worlds' Ash, Memories Echo. Welcome to The World Senlo. On April
            1st, 1116, in the Senlo calendar, a colossal rift materialized,
            intersecting realms and changing the world forever. Crystals with
            unknown energies gave rise to a new era of humanity, and individuals
            known as “Echomancers” began to awaken with special abilities. As
            the Director of S.E.E.D., recruit visitors from different realms,
            strategize to face unprecedented threats, and reveal the secrets
            that drive the world’s future.
          </i>
        </p>
        <h5>Key Features</h5>
        <ul>
          <li>
            <strong>Real-time Tactical Combat:</strong> Engage in fast-paced,
            strategic battles where you control a team of characters with unique
            abilities.
          </li>
          <li>
            <strong>Exploration & Interaction:</strong> Traverse multiple
            realms, uncover hidden secrets, and interact with richly detailed
            environments.
          </li>
          <li>
            <strong>Diverse Characters & Stories:</strong> Unlock and develop
            characters with varied backgrounds and personalities, each
            contributing to the unfolding narrative.
          </li>
          <li>
            <strong>Blended Art Style:</strong> Experience a visually
            captivating world that combines detailed 2D character art with
            immersive 3D environments.
          </li>
          <li>
            <strong>Team Customization:</strong> Build and optimize your team
            with an array of characters and abilities to suit your playstyle.
          </li>
          <li>
            <strong>Echoing Nexus (Roguelike Mode):</strong> Challenge yourself
            in procedurally generated levels within the Echoing Nexus, where
            each run offers new surprises, powerful rewards, and unique
            progression paths.
          </li>
          <li>
            <strong>Story-Driven Campaign:</strong> Lead the S.E.E.D. as you
            unravel interdimensional mysteries and face powerful enemies.
          </li>
        </ul>
        <SectionHeader title="Official images" />
        <Row xs={1} xl={2}>
          <Col>
            <StaticImage src="../../../images/ash/promo.webp" alt="Guides" />
          </Col>
          <Col>
            <StaticImage src="../../../images/ash/promo2.webp" alt="Guides" />
          </Col>
          <Col>
            <StaticImage src="../../../images/ash/promo3.webp" alt="Guides" />
          </Col>
          <Col>
            <StaticImage src="../../../images/ash/promo4.webp" alt="Guides" />
          </Col>
        </Row>
        <SectionHeader title="Official links" />
        <ul>
          <li>
            Official Website:{' '}
            <a
              href="https://ae.neocraftstudio.com/"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
          <li>
            Official Discord:{' '}
            <a
              href="https://discord.gg/ashechoesofficial"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
          <li>
            Official YouTube:{' '}
            <a
              href="https://www.youtube.com/@AshEchoes"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
          <li>
            Official Twitter:{' '}
            <a href="https://x.com/AshEchoes" target="_blank" rel="noreferrer">
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
          <li>
            Google Play Store:{' '}
            <a
              href="https://play.google.com/store/apps/details?id=com.emagroups.ash"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
          <li>
            Apple Store:{' '}
            <a
              href="https://apps.apple.com/us/app/ash-echoes-global/id6484504004?mt=8"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
          <li>
            STEAM:{' '}
            <a
              href="https://store.steampowered.com/app/3068700/ASH_ECHOES/"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon width="18" icon={faArrowUpRightFromSquare} />{' '}
              Open in new window
            </a>
          </li>
        </ul>
        <SectionHeader title="Trailer" />
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="1F92bTGP49o" />
          </Col>
        </Row>
        <SectionHeader title="Video" />
        <p>If you want to see the gameplay check the videos below:</p>
        <Row className="video">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="PLkFVB91pyU" />
          </Col>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="5WwDtTzdYMo" />
          </Col>
        </Row>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AshGuidesIntroPage;

export const Head: React.FC = () => (
  <Seo
    title="Introduction to the game | Ash Echoes | Prydwen Institute"
    description="Basic information about the Ash Echoes gacha game."
    game="ash"
  />
);
